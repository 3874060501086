<template>
  <router-view />
</template>

<script>
import cpmUser from '@/mixins/module/cpmUser';

export default {
  name: 'AccountUserCpm',
  data: function() {
    return {
      pageName: 'アカウント設定',
    };
  },
  mixins: [cpmUser],
};
</script>
